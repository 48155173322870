body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background: linear-gradient(to bottom, #222222, #112222);
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  color: white;
  font-family: sans-serif;
  padding-bottom: 80px;
}

.App-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.App-link {
  color: orange;
}

.content {
  margin-top: 20px;
  width: 70%;
  background: linear-gradient(to bottom, #112222, #000);
  border: 1px solid white;
  padding-bottom: 14px;
}

.footer {
  width: 70%;
  margin-top: 20px;
  padding-top: 26px;
  background: linear-gradient(to bottom, #112222, #000);
  border: 1px solid white;
  padding-bottom: 20px;
  text-align: center;
}

.title {
  -webkit-align-self: center;
          align-self: center;
  color: #FFFFFF55;
  font-size: 48px;
  margin-top: 34px;
  margin-bottom: 12px;
  margin-bottom: 12px; 
  font-weight: bold;
}

.subtitle {
  font-weight: bold;
  font-size: 32px;
  margin-top: 27px;
  margin-left: 25px;
  margin-bottom: 30px;
  -webkit-align-self: left;
          align-self: left;
  -webkit-align-content: left;
          align-content: left;
}

.text {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 0px;
  margin-top: -5px;
}

.itemtitle {
  margin-left: 25px;
  margin-top: 26px;
  font-size: 25px;
  padding-bottom: 5px;
  font-weight: bold;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

